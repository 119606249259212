import { WidgetViewModel } from '../../viewModel/viewModel';
import { BookingsAPI } from '../../api/BookingsApi';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { enrichServices } from '../../utils/services/services';

export type LoadServicesByPageAction = (requestedPage: number) => void;

export const createLoadServicesByPageAction =
  ({
    widgetViewModel,
    bookingsApi,
    flowAPI,
    activeFeatures,
    isPricingPlanInstalled,
    serviceListContext,
    setProps,
  }: {
    widgetViewModel: WidgetViewModel;
    bookingsApi: BookingsAPI;
    setProps: Function;
    flowAPI: ControllerFlowAPI;
    activeFeatures: any;
    isPricingPlanInstalled: boolean;
    serviceListContext: any;
  }): LoadServicesByPageAction =>
  async (requestedPage) => {
    const { services: currentServices, servicesPagingMetadata } =
      widgetViewModel;

    if (!servicesPagingMetadata) {
      return;
    }

    const { totalPages, maxPageLoaded, minPageLoaded } = servicesPagingMetadata;

    const shouldLoadPreviousPage =
      requestedPage < minPageLoaded && minPageLoaded > 0;

    const shouldLoadNextPage =
      requestedPage > maxPageLoaded && maxPageLoaded < totalPages;

    if (shouldLoadPreviousPage || shouldLoadNextPage) {
      const servicesApiResponse = await bookingsApi.queryServices({
        page: requestedPage,
      });
      const { services: loadedServices } = servicesApiResponse.data;

      const enrichedLoadedServices = await enrichServices({
        services: loadedServices ?? [],
        flowAPI,
        activeFeatures,
        isPricingPlanInstalled,
        isAnywhereFlow: serviceListContext.isAnywhereFlow,
      });

      widgetViewModel.services = shouldLoadNextPage
        ? [...currentServices, ...enrichedLoadedServices]
        : [...enrichedLoadedServices, ...currentServices];

      widgetViewModel.servicesPagingMetadata = {
        totalPages,
        maxPageLoaded: shouldLoadNextPage ? requestedPage : maxPageLoaded,
        minPageLoaded: shouldLoadPreviousPage ? requestedPage : minPageLoaded,
      };

      setProps({
        widgetViewModel: {
          ...widgetViewModel,
        },
      });
    }
  };
