import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  createServiceImageViewModel,
  ServiceImageViewModel,
} from './serviceImageViewModel/serviceImageViewModel';
import {
  BodyViewModel,
  createBodyViewModel,
} from './bodyViewModel/bodyViewModel';
import { createFilterOptions } from './filterOptions/filterOptions';
import {
  filterServicesBySelectedTab,
  filterServicesBySettings,
  getCategoriesFromServices,
} from '../utils/services/services';
import {
  createHeaderViewModel,
  HeaderViewModel,
} from './headerViewModel/headerViewModel';
import {
  FilterOption,
  EnrichedService,
  ServiceListLayoutOptions,
  ViewMode,
  ServicesPagingMetadata,
} from '../types/types';
import { BusinessInfo as LegacyBusinessInfo } from '../../legacy/types';
import settingsParams from '../components/BookOnline/settingsParams';
import {
  createDialogViewModel,
  DialogViewModel,
} from './dialogViewModel/dialogViewModel';
import { Location } from '@wix/ambassador-bookings-services-v2-service/types';
import { ServiceListContext } from '../context/createServiceListContext';
import { ServiceAvailabilityMap } from '../api/BookingsApi';
import { ServiceListSettings } from '../../legacy/appSettings/appSettings';

export type WidgetViewModel = {
  services: EnrichedService[];
  servicesPagingMetadata: ServicesPagingMetadata;
  businessInfo: LegacyBusinessInfo;
  filterOptions: FilterOption[];
  serviceImageViewModel: ServiceImageViewModel;
  headerViewModel?: any;
  bodyViewModel: BodyViewModel;
  seo?: {
    shouldListServicesFromOtherCategories: boolean;
    allServices: EnrichedService[];
  };
  isSEO: boolean;
  serviceListLayout: ServiceListLayoutOptions;
  coursesAvailability?: ServiceAvailabilityMap;
  shouldWorkWithAppSettings: boolean;
  dialogViewModel: DialogViewModel;
  isMultiServiceAppointmentEnabled: boolean;
};

export const createWidgetViewModel = async ({
  flowAPI,
  scale,
  viewMode,
  shouldWorkWithAppSettings,
  businessLocations,
  allServices,
  servicesPagingMetadata,
  serviceListContext,
  appSettings,
  filterOptions = [],
}: {
  flowAPI: ControllerFlowAPI;
  scale: number;
  viewMode: ViewMode;
  shouldWorkWithAppSettings: boolean;
  businessLocations: Location[];
  allServices: EnrichedService[];
  servicesPagingMetadata: ServicesPagingMetadata;
  serviceListContext: ServiceListContext;
  appSettings?: ServiceListSettings;
  filterOptions?: FilterOption[];
}): Promise<WidgetViewModel> => {
  const {
    settings,
    environment: { isMobile, isEditor },
  } = flowAPI;
  const { businessInfo, isPricingPlanInstalled } = serviceListContext;

  if (shouldWorkWithAppSettings && isEditor) {
    allServices = filterServicesBySettings({
      flowAPI,
      services: allServices,
      shouldWorkWithAppSettings,
      appSettings,
    });
  }

  const categories = getCategoriesFromServices(allServices);

  filterOptions = flowAPI.experiments.enabled(
    'specs.bookings.fetchTabsInServiceList',
  )
    ? filterOptions
    : createFilterOptions({
        flowAPI,
        businessLocations,
        categories,
        services: allServices,
      });

  const filteredServices = filterServicesBySelectedTab({
    filterOptions,
    services: allServices,
    settings,
  });

  const serviceListLayout = settings.get(settingsParams.serviceListLayout);

  const serviceImageViewModel = createServiceImageViewModel({
    settings,
    isMobile,
    serviceListLayout,
  });

  const bodyViewModel = createBodyViewModel({
    flowAPI,
    scale,
    imageAspectRatio: serviceImageViewModel.aspectRatio,
    serviceListLayout,
    services: filteredServices,
    isPricingPlanInstalled,
  });

  const headerViewModel = createHeaderViewModel(settings, filterOptions);

  const shouldListServicesFromOtherCategories =
    viewMode === ViewMode.PAGE &&
    filteredServices.length !== allServices.length;
  const seo = {
    shouldListServicesFromOtherCategories,
    allServices,
  };

  const dialogViewModel = createDialogViewModel();

  return {
    services: filteredServices,
    filterOptions,
    serviceListLayout,
    serviceImageViewModel,
    headerViewModel,
    bodyViewModel,
    businessInfo,
    seo,
    isSEO: flowAPI.environment.isSEO,
    shouldWorkWithAppSettings,
    dialogViewModel,
    isMultiServiceAppointmentEnabled:
      serviceListContext.isMultiServiceAppointmentEnabled,
    servicesPagingMetadata,
  };
};

export type WidgetErrorStateViewModel = {
  headerViewModel: HeaderViewModel;
  errorText: string;
};

export const createWidgetErrorStateViewModel = ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): WidgetErrorStateViewModel => {
  const {
    settings,
    translations: { t },
  } = flowAPI;

  const errorStateFilterOptions: FilterOption[] = [];

  const headerViewModel = createHeaderViewModel(
    settings,
    errorStateFilterOptions,
  );

  return {
    headerViewModel,
    errorText: t('error-state.text'),
  };
};
