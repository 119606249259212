import {
  CatalogServiceDto,
  ReservedLocationIds,
} from '@wix/bookings-uou-types';
import { ALL_SERVICES } from '../../consts';
import {
  FilterOption,
  FilterServicesByOptions,
  EnrichedService,
} from '../../types/types';
import settingsParams from '../../components/BookOnline/settingsParams';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getBlockNavigationReason } from './getBlockNavigationReason/getBlockNavigationReason';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { bulkCreateAnywhereUrl } from '@wix/wix-anywhere-api';
import {
  Category,
  Location,
  LocationType,
  Service,
  ServiceType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ServiceListSettings } from '../../../legacy/appSettings/appSettings';
import { buildQueryServicesFilter } from '../filters/buildQueryServicesFilter';

export const filterServicesBySelectedTab = ({
  filterOptions,
  services,
  settings,
}: {
  filterOptions?: FilterOption[];
  services: EnrichedService[];
  settings: any;
}): EnrichedService[] => {
  if (!services.length || !filterOptions) {
    return services;
  }

  const selectedFilterId = filterOptions.find(
    ({ isSelected }: FilterOption) => isSelected,
  )?.id;

  if (
    !selectedFilterId ||
    selectedFilterId === ALL_SERVICES ||
    !settings.get(settingsParams.isListFilterVisible)
  ) {
    return services;
  }

  const filterServicesBy = settings.get(settingsParams.filterServicesBy);
  if (filterServicesBy === FilterServicesByOptions.CATEGORIES) {
    return filterServicesByCategoryId(services, selectedFilterId);
  }
  return filterServicesByLocationId(services, selectedFilterId);
};

export const filterServicesByCategoryId = (
  services: EnrichedService[],
  categoryId: string,
) => {
  return services.filter((service) => service.category?.id === categoryId);
};

export const filterServicesByLocationId = (
  services: EnrichedService[],
  locationId: string,
) => {
  if (locationId === ReservedLocationIds.OTHER_LOCATIONS) {
    return services.filter((service) =>
      service.locations?.find(
        (location) => location.type !== LocationType.BUSINESS,
      ),
    );
  }
  return services.filter((service) =>
    service.locations?.find((location) => location.business?.id === locationId),
  );
};

export const enrichServices = ({
  services,
  isAnywhereFlow = false,
  flowAPI,
  activeFeatures,
  isPricingPlanInstalled,
}: {
  services: Service[];
  flowAPI: ControllerFlowAPI;
  isAnywhereFlow?: boolean;
  isPricingPlanInstalled: boolean;
  activeFeatures: GetActiveFeaturesResponse;
}): Promise<EnrichedService[]> =>
  Promise.all(
    services.map(async (service) => {
      const enrichedService: EnrichedService = service as any;

      if (isAnywhereFlow) {
        const anywhereURL = await bulkCreateAnywhereUrl({
          urls: [service.urls?.servicePage?.relativePath!],
          baseUrl: service.urls?.servicePage?.url!.replace(
            service.urls?.servicePage?.relativePath!,
            '',
          )!,
        });
        service.urls!.servicePage!.url = anywhereURL[0];
      }

      const { firstSessionStart, lastSessionEnd } = service.schedule!;
      const isPassedStartDate =
        !firstSessionStart || firstSessionStart < new Date();
      const isPassedEndDate =
        service.type === ServiceType.COURSE &&
        (!lastSessionEnd || lastSessionEnd < new Date());

      enrichedService.displayOnlyNoBookFlow =
        (isPassedStartDate && isPassedEndDate) ||
        !service.onlineBooking?.enabled;

      enrichedService.blockNavigationReason = getBlockNavigationReason({
        service: enrichedService,
        flowAPI,
        isPricingPlanInstalled,
        activeFeatures,
      });

      return enrichedService;
    }),
  );

export const getCategoriesFromServices = (
  services: EnrichedService[],
): Category[] => {
  const categoryIdCategoryMap = new Map<string, Category>();

  services.forEach(({ category }) => {
    categoryIdCategoryMap.set(category?.id!, category!);
  });

  return Array.from(categoryIdCategoryMap.values()).sort(
    (category1, category2) => category1?.sortOrder! - category2?.sortOrder!,
  );
};

export const getBusinessLocationsFromServices = (
  services: EnrichedService[],
): Location[] => {
  const locationIdLocationMap = new Map<string, Location>();

  services.forEach(({ locations }) => {
    locations?.forEach((location) => {
      if (location.type === LocationType.BUSINESS) {
        locationIdLocationMap.set(location?.business?.id!, location);
      }
    });
  });

  return Array.from(locationIdLocationMap.values());
};

export const isServiceV2 = (
  service: Service | CatalogServiceDto,
): service is Service => !!(service as Service).bookingPolicy;

export const filterServicesBySettings = <ServiceArg extends Service>({
  flowAPI,
  services,
  shouldWorkWithAppSettings,
  appSettings,
}: {
  services: ServiceArg[];
  shouldWorkWithAppSettings: boolean;
  appSettings?: ServiceListSettings;
  flowAPI: ControllerFlowAPI;
}) => {
  const { categoryIds, limit, locationIds, serviceIds, staffMemberIds } =
    buildQueryServicesFilter({
      flowAPI,
      shouldWorkWithAppSettings,
      appSettings,
    });

  if (categoryIds) {
    services = services.filter(({ category }) =>
      categoryIds.includes(category?.id!),
    );
  }

  if (locationIds) {
    services = services.filter(({ locations }) => {
      if (locations?.[0]?.type !== LocationType.BUSINESS) {
        return locationIds.includes(ReservedLocationIds.OTHER_LOCATIONS);
      }

      return locations.some(({ business }) =>
        locationIds.includes(business?.id!),
      );
    });
  }

  if (serviceIds) {
    services = services.filter(({ id }) => serviceIds.includes(id!));
  }

  if (staffMemberIds) {
    services = services.filter(({ staffMemberIds: serviceStaffMemberIds }) => {
      return staffMemberIds?.some((id) => serviceStaffMemberIds?.includes(id));
    });
  }

  if (limit) {
    services = services.slice(0, limit);
  }

  return services;
};
